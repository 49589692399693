import { MainButton } from "components/buttons";
import TemplateAuthentification from "components/templates/template-authentification.component";
import { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

const UpdatePage: React.FC = () => {
  const navigation: NavigateFunction = useNavigate()

  useEffect(() => {
    const isUpdated = localStorage.getItem('isUpdated');

    if (isUpdated) {
      // Supprimer l'indicateur après la redirection
      localStorage.removeItem('isUpdated');
      // Rediriger vers la nouvelle page
      navigation('/dashboard/semaine');
    }
  }, [navigation]);

  const updateApp = () => {
    localStorage.setItem('isUpdated', 'true');
    window.location.reload();
  }

  return (
    <TemplateAuthentification title="Mise à jour" secondElementLargeDiv={{ content: <div className="w-[80%]"><img src="/images/undraw_update.svg" alt="Mise à jour" /></div>, side: 'left' }} >
        <div className="sm:flex sm:flex-col sm:justify-around sm:content-around sm:h-full">
            <div className="sm:hidden">
                <img src="/images/undraw_update.svg" alt="Une erreur" className="inline-block w-[60%]" />
                <div className="h-4"></div>
            </div>
            <p>Une nouvelle mise à jour de l'application est disponible !</p>
            <div className="sm:h-0 h-8"></div>
            <div className="flex justify-center">
                <MainButton label="Mettre à jour" isDisabled={false} isLoading={false} onClick={() => updateApp()} />
            </div>
        </div>
    </TemplateAuthentification>
  )
}

export default UpdatePage