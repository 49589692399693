interface ICenterModalProps {
    onCancel: () => void,
    children: React.ReactNode
}

const CenterModal: React.FC<ICenterModalProps> = ({ onCancel, children }) => {

    return (
        <div className="fixed inset-0 bg-black/50 z-[100] duration-[2000ms]" onClick={onCancel}>
            <div className="absolute w-[80%] bg-white rounded-lg p-4 py-6 duration-[500ms] right-1/2 top-1/2 -translate-y-1/2 translate-x-1/2 animate-[slideUp_10s_forwards]" onClick={(e: any) => {e.stopPropagation()}}>
                {children}
            </div>
        </div>
    )
}

export default CenterModal