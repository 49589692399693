import { getMailsApi } from "api/user/mails/get-mails.api";
import MailInformation from "components/informations/mail-information.component";
import FlatInformation from "components/informations/flat-information.component";
import TemplateJourney from "components/templates/template-journey.component";
import useAuthentification from "hooks/useAuthentification.hook";
import { Inbox, MailQuestion } from "lucide-react";
import { Mail } from "models";
import { NavBarSection } from "models/enums/nav-bar-section.enum";
import { MailType } from "models/mail-type.model";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { getMailsTypesApi } from "api/user/mails/get-mails-types.api";
import { CenterPageLoader } from "components/loaders";
import { SecondaryButton } from "components/buttons";

const SettingMailPage: React.FC = () => {
    const { authentification, setAuthentification } = useAuthentification();
    const navigate: NavigateFunction = useNavigate();

    const [page, setPage] = useState<number>(1)
    const [mails, setMails] = useState<Mail[] | null>(null)
    const [pagination, setPagination] = useState<{ page: number, limit: number, total: number } | null>(null)
    const [mailTypes, setMailTypes] = useState<MailType[] | null>(null)

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (authentification) {
            getMails()
        }
    }, [page])

    const getMails = async () => {
        setLoading(true)
        const response = await getMailsApi({ page: page }, { navigation: navigate, authentification: authentification, setAuthentification: setAuthentification })
        setLoading(false)

        if (response.success && response.data !== null) {
            setMails(response.data.mails)
            setPagination(response.data.pagination)
            getMailTypes()
        }
    }

    const getMailTypes = async () => {
        const response = await getMailsTypesApi({ navigation: navigate, authentification: authentification, setAuthentification: setAuthentification })

        if (response.success && response.data !== null) {
            setMailTypes(response.data!)
        }
    }

    return (
        !authentification ? <></> :
        <TemplateJourney title="Mes mails" onClickReturn={() => navigate('/dashboard/compte')} selectedSection={NavBarSection.PROFILE}>
            <div className="w-full flex flex-col items-center gap-6 my-4">
                <FlatInformation onClick={() => navigate("/parametres/mails/preferences")}>
                    <div className="w-full flex flex-row items-center gap-2">
                        <div className="p-2">
                            <MailQuestion />
                        </div>
                        <p>Modifier mes préférences</p>
                    </div>
                </FlatInformation>
            </div>
            <div className="text-base w-full mx-6 my-4 mt-8 flex flex-row items-center gap-2">
                <Inbox />
                <p>Mails envoyés</p>
            </div>
            {
                pagination && page > 1 && (
                    <>
                        <SecondaryButton label="Page précédente" isDisabled={false} isLoading={loading} onClick={() => setPage(prev => prev - 1)} />
                        <div className="h-4"></div>
                    </>
                )
            }
            {
                loading || mails === null || mailTypes === null ? <CenterPageLoader content="Récupération des mails..." /> : (
                    mails.length === 0 ? (
                        <div className="absolute right-1/2 top-1/2 w-full -translate-y-1/2 translate-x-1/2">
                            <p className="text-center">Aucun mail n'a été enregistré.</p>
                        </div>
                    ) : (
                        <>
                            <div className="text-base w-full flex flex-col items-center gap-4">
                                {
                                    mails.map((mail: Mail, index: number) => {
                                        return (
                                            <MailInformation key={index} mail={mail} mailType={mailTypes.find(t => t.mailTypeId === mail.mailTypeId)} />
                                        )
                                    })
                                }
                            </div>
                            {
                                pagination && page * pagination.limit < pagination.total && (
                                    <>
                                        <div className="h-4"></div>
                                        <SecondaryButton label="Page suivante" isDisabled={false} isLoading={loading} onClick={() => setPage(prev => prev + 1)} />
                                    </>
                                )
                            }
                            <div className="h-40"></div>
                        </>
                    )
                )
            }
        </TemplateJourney>
    )
}

export default SettingMailPage